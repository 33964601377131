<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row>
        <div v-if="init_loading">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card class="mt-15">
                <v-card-title>Export User List</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete v-model="selected_usertype" placeholder="Select User Type"
                                :items="usertype_list" item-text="name" item-value="id" outlined class="text" dense
                                style="float: right;">
                                <template #label>
                                    User Type<span style="color: red;">*</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <div>
                                <center>
                                    <v-btn style="float: left;" color="success" class="mb-4"
                                        @click="fetchData()" :loading="loading">Fetch</v-btn>
                                </center>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <table id="exceltable" class="borderclass" style="display: none;">
                <thead>
                    <tr>
                        <th class="borderclass2">PRN No./Employee Code</th>
                        <th class="borderclass2">Accession No.</th>
                        <th class="borderclass2">Borrowed Date</th>
                        <th class="borderclass2">Due Date </th>
                        <th class="borderclass2">Return Date </th>
                        <th class="borderclass2">Remark</th>
                        <th class="borderclass2">Username</th>
                        <th class="borderclass2">Is Issue(YES/NO)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in bl_data" :key="index">
                        <td class="borderclass1">{{ item.prn_emp_code }}</td>
                        <td class="borderclass1">{{ item.accession_number }}</td>
                        <td class="borderclass1">{{ item.borrowing_date }}</td>
                        <td class="borderclass1">{{ item.due_date }}</td>
                        <td class="borderclass1">{{ item.return_date }}</td>
                        <td class="borderclass1">{{ item.remark }}</td>
                        <td class="borderclass1">{{ item.username }}</td>
                        <td class="borderclass1">
                            <span v-if="item.isactive==true">YES</span>
                            <span v-else>NO</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <v-card class="mt-10" v-if="bl_data.length > 0">
                <v-card-text>
                    <v-data-table :headers="headers" :items="bl_data" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line
                                    hide-details> </v-text-field>
                                <v-btn id="export" @click="exportexcel()" color="success" style="float:right;" dark class="ml-5">
                                    <v-icon dark>mdi-file-excel</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.isactive="{ item }">
                            <span v-if="item.isactive == true">YES</span>
                            <span v-else>NO </span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
    data() {
        return {
            snackbar: false,
            snackbar_msg: "",
            search: "",
            init_loading: false,
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'Reports',
                    disabled: false,
                    href: '/reports',
                },
                {
                    text: 'Issued Book Details',
                    disabled: true,
                    href: '',
                },
            ],
            usertype_list: [],
            selected_usertype: "",
            bl_data: [],
            headers: [
                { text: "PRN No./Employee Code", value: "prn_emp_code" },
                { text: "Accession No.", value: "accession_number" },
                { text: "Borrowed Date", value: "borrowing_date" },
                { text: "Due Date ", value: "due_date" },
                { text: "Return Date", value: "return_date", align: "center" },
                { text: "Remark", value: "remark", align: "center" },
                { text: "Username", value: "username", align: "center" },
                { text: "Is Issue(YES/NO)", value: "isactive" },
            ],
            loading: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        //End

        init() {
            this.init_loading = true;
            axios
                .post("/LibrarianNew/getUserTypeList")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.usertype_list = res.data.usertype_list;
                        this.init_loading = false;
                    }
                })
        },
        //End

        fetchData() {
            if (!this.selected_usertype) {
                this.showSnackbar("#b71c1c", "Please Select User Type");
                return;
            }
            else {

                const data = {
                    usertype_id: this.selected_usertype,
                    // colData: this.colData
                };
                this.loading = true;
                axios
                    .post("/LibrarianNew/getExportUserData", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.loading = false;
                             this.bl_data = res.data.bl_data;

                            if (this.bl_data.length == 0) {
                                this.showSnackbar("#b71c1c", "No data available ");
                            }
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong.."); // show snackbar
                        window.console.log(error);
                        this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        //End

        exportexcel() {

            $("#exceltable").table2excel({
                name: "Failed Record Book",
                exclude_img: false,
                filename: "ExportUserData", //do not include extension
                fileext: ".csv", // file extension
                preserveColors: false
            });
        },

    },
};
</script>

<style scoped>
.borderclass {
    border: 1px solid black !important;
    border-collapse: collapse;
    padding: 5px;
}

.borderclass1 {
    border: 1px solid black !important;
    border-collapse: collapse;
    padding: 5px;
}

.borderclass2 {
    border: 1px solid black !important;
    border-collapse: collapse;
    padding: 5px;
}

.borderclass2:hover {
    cursor: pointer;
    background: rgb(229, 255, 211);
}

table,
td {
  border: 1px solid lightgrey;
  padding: 10px;
}
table,
th {
  padding: 10px;
}
</style>